import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { animateScroll as scroll } from "react-scroll";
import PricingPlan from "../components/PricingPlan";

const Pricing = () => {
	useEffect(() => {
		scroll.scrollToTop({
			duration: 500,
			smooth: true,
			offset: -70, // Adjust this value if you have a fixed header
		});
	}, []);

	return (
		<div>
			<Helmet>
				<title>Aira | Pricing</title>
				<meta name="title" content="Aira | Blog" />

				{/* <!-- Open Graph / Facebook --> */}
				<meta
					property="og:image"
					content="/assets/images/meta/aira_pricing_social_media_preview.png"
				/>

				{/* <!-- Twitter --> */}
				<meta
					property="twitter:image"
					content="/assets/images/meta/aira_pricing_social_media_preview.png"
				/>
			</Helmet>
			<div id="pricing-top">
				<PricingPlan />
			</div>
		</div>
	);
};

export default Pricing;
