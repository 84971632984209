import React from "react";
import { Check, Minus } from "lucide-react";
import "../styles/PricingPlan.css";

const PricingPlan = () => {
	const plans = [
		{
			name: "Starter Plan",
			price: "$49/month",
			users: "1",
			features: {
				"Resume Parsing": "Basic (up to 100/month)",
				"Email Notifications": true,
				"User Interface": "Single-user navigation",
				"Workflow Customization": "Basic",
				"Candidate Tracking": true,
				Reporting: "Simple",
				Support: "Email-only",
				Records: "Limited",
				"Collaboration Tools": true,
				"Candidate Pipelines": true,
				Analytics: true,
				"AI Insights": true,
				"Bulk Email": true,
				"Interview Scheduling": true,
				"Feedback Collection": true,
				"Multi-department Tools": true,
				"Account Manager": true,
				"Third-party Integrations": true,
				"Security Compliance": true,
				SLA: true,
				"Custom Analytics": false,
				"Team Onboarding": false,
				"Tech Support": false,
			},
		},
		{
			name: "Team Plan",
			price: "Contact Us",
			users: "5–20 (Adjustable for team size)",
			features: {
				"Resume Parsing": "Advanced (up to 1,000/month)",
				"Email Notifications": true,
				"User Interface": "Team-based navigation",
				"Workflow Customization": "Advanced",
				"Candidate Tracking": true,
				Reporting: "Advanced",
				Support: "Priority email",
				Records: "Unlimited",
				"Collaboration Tools": true,
				"Candidate Pipelines": true,
				Analytics: true,
				"AI Insights": true,
				"Bulk Email": true,
				"Interview Scheduling": true,
				"Feedback Collection": true,
				"Multi-department Tools": true,
				"Account Manager": true,
				"Third-party Integrations": true,
				"Security Compliance": true,
				SLA: true,
				"Custom Analytics": true,
				"Team Onboarding": true,
				"Tech Support": true,
			},
		},
		{
			name: "Enterprise Plan",
			price: "Custom",
			users: "Unlimited",
			features: {
				"Resume Parsing": "Advanced (custom limit)",
				"Email Notifications": true,
				"User Interface": "Multi-department navigation",
				"Workflow Customization": "Enhanced",
				"Candidate Tracking": true,
				Reporting: "Custom",
				Support: "24/7 dedicated",
				Records: "Unlimited",
				"Collaboration Tools": true,
				"Candidate Pipelines": true,
				Analytics: true,
				"AI Insights": true,
				"Bulk Email": true,
				"Interview Scheduling": true,
				"Feedback Collection": true,
				"Multi-department Tools": true,
				"Account Manager": true,
				"Third-party Integrations": true,
				"Security Compliance": true,
				SLA: true,
				"Custom Analytics": true,
				"Team Onboarding": true,
				"Tech Support": true,
			},
		},
	];

	const categories = {
		"Core Features": [
			"Resume Parsing",
			"Email Notifications",
			"User Interface",
			"Workflow Customization",
			"Candidate Tracking",
			"Reporting",
			"Support",
			"Records",
		],
		Collaboration: ["Collaboration Tools", "Candidate Pipelines"],
		"Advanced Features": [
			"Analytics",
			"AI Insights",
			"Bulk Email",
			"Interview Scheduling",
			"Feedback Collection",
		],
		"Enterprise Solutions": [
			"Multi-department Tools",
			"Account Manager",
			"Third-party Integrations",
			"Security Compliance",
			"SLA",
			"Custom Analytics",
			"Team Onboarding",
			"Tech Support",
		],
	};

	const handleButtonClick = url => {
		window.open(url, "_blank", "noopener,noreferrer");
	};

	return (
		<div className="container mt-5 mb-5">
			<h1 className="text-center mb-4 fw-bold">Pricing Plans</h1>
			<div className="table-responsive">
				<table className="table categorized-pricing-table">
					<thead>
						<tr>
							<th></th>
							{plans.map((plan, index) => (
								<th
									key={index}
									className={`text-center ${
										plan.name === "Team Plan" ? "bg-team-plan" : ""
									} ${
										plan.name === "Enterprise Plan" ? "bg-enterprise-plan" : ""
									}`}
								>
									<h3 className="fw-bold">{plan.name}</h3>
									<div className="price">{plan.price}</div>
									<div className="users fs-6">Users: {plan.users}</div>
									<button
										onClick={
											() =>
												handleButtonClick(
													"https://calendly.com/d/ck5g-53m-559/aira-demo"
												)
											// handleButtonClick(
											// 	plan.name === "Starter Plan"
											// 		? "https://app.helloaira.io/register"
											// 		: "https://calendly.com/d/ck5g-53m-559/aira-demo"
											// )
										}
										className="btn btn-dark mt-3 fw-bold"
									>
										{plan.name === "Starter Plan"
											? "Get Started"
											: "Contact Sales"}
									</button>
								</th>
							))}
						</tr>
					</thead>
					<tbody>
						{Object.entries(categories).map(([category, features]) => (
							<React.Fragment key={category}>
								{features.map(feature => (
									<tr key={feature}>
										<td className="feature-name">{feature}</td>
										{plans.map((plan, planIndex) => (
											<td key={planIndex} className="text-center">
												{typeof plan.features[feature] === "boolean" ? (
													plan.features[feature] ? (
														<Check className="text-success" size={20} />
													) : (
														<Minus className="text-muted" size={20} />
													)
												) : (
													plan.features[feature]
												)}
											</td>
										))}
									</tr>
								))}
							</React.Fragment>
						))}
					</tbody>
				</table>

				<div className="mt-5 text-center">
					<h2 className="fw-bold fs-4">
						Curious to know which package works best for you?
					</h2>
					<p className="fw-bold fs-6">Feel free to chat with our team!</p>
					<button
						className="btn btn-dark fw-bold"
						onClick={() =>
							handleButtonClick("https://calendly.com/d/ck5g-53m-559/aira-demo")
						}
					>
						Chat with us!
					</button>
				</div>
			</div>
		</div>
	);
};

export default PricingPlan;
