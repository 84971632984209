import React from "react";
import { Link } from "react-router-dom"; // Ensure you're using React Router for routing
import "../styles/PricingLink.css";

const PricingLink = () => {
	return (
		<>
			<div id="pricing-link-section" className="container mx-auto py-5 mt-4">
				<div className="text-center">
					<h2 className="text-3xl fw-bold mb-4">Discover Our Pricing Plans</h2>
					<p className="mb-6 text-gray-700 mx-auto">
						Choose the plan that best fits your business needs and scale your
						recruitment efforts efficiently.
					</p>

					<Link to="/pricing">
						<button className="btn btn-dark me-3 px-3 py-2 rounded-pill">
							View Pricing Details
						</button>
					</Link>
				</div>
			</div>
		</>
	);
};

export default PricingLink;
