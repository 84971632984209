import React from "react";
import { Link } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import logoImg from "../assets/images/logo1@2x.png";
import "../styles/Header.css";

const Header = () => {
	return (
		<header className="shadow-sm py-3">
			<div className="container-fluid">
				<div className="row align-items-center">
					<div className="col-12 d-flex justify-content-between">
						<nav className="navbar navbar-expand-lg navbar-light p-0 align">
							<Link className="navbar-brand" to="/">
								<img src={logoImg} alt="Logo" width="72" height="28" />
							</Link>
							<button
								className="navbar-toggler button-size"
								type="button"
								data-toggle="collapse"
								data-target="#navbarNav"
								aria-controls="navbarNav"
								aria-expanded="false"
								aria-label="Toggle navigation"
							>
								<span className="navbar-toggler-icon"></span>
							</button>
							<div className="collapse navbar-collapse" id="navbarNav">
								<ul className="navbar-nav mx-auto">
									<li className="nav-item active">
										<Link className="nav-link" to="/">
											Home
										</Link>
									</li>
									{/* <li className="nav-item">
                    <Link className="nav-link" to="/">Find Jobs</Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/">Check Resume</Link>
                  </li> */}
									<li className="nav-item">
										<Link className="nav-link" to="/blogs">
											Blogs
										</Link>
									</li>
									<li className="nav-item">
										<Link className="nav-link" to="/pricing">
											Pricing
										</Link>
									</li>
								</ul>
							</div>
						</nav>
						<div className="d-flex">
							<ul className="navbar-nav d-flex flex-row nav-center">
								<li className="nav-item">
									<Link
										className="btn btn-login mr-2"
										to="https://app.helloaira.io/"
									>
										Login
									</Link>
								</li>
								<li className="nav-item">
									<Link
										className="btn btn-try-free"
										to="https://app.helloaira.io/register"
									>
										Get Started
									</Link>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</header>
	);
};

export default Header;
